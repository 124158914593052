import React from "react";
import "./sign_up_flow.css";
import { Provider as StyletronProvider } from "styletron-react";
import styled, { ThemeProvider } from "styled-components";
import { BaseProvider } from "baseui";
import deprecatedTheme from "../styling/deprecated_theme";
import baseuiTheme from "../styling/baseui_theme";
import { Client as Styletron } from "styletron-engine-atomic";
import Card from "../components/layout/card";
import { HeadingSmall, ParagraphMedium } from "baseui/typography";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";
const engine = new Styletron();

const StyledCard = styled(Card)`
  max-width: 540px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.sizing.scale900};
  padding: ${({ theme }) => theme.sizing.scale1000} ${({ theme }) => theme.sizing.scale1200};
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: ${({ theme }) => theme.typography.HeadingXSmall.lineHeight};
  font-family: ${({ theme }) => theme.typography.LabelMedium.fontFamily};
`;

const StyledHeadingSmall = styled(HeadingSmall)`
  margin-top: ${({ theme }) => theme.sizing.scale1000};
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
`;

const StyledParagraphMedium = styled(ParagraphMedium)`
  color: ${({ theme }) => theme.colors.contentTertiary};
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SsoUniqueIdentifierMismatch({ programData }: { programData: any }) {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={baseuiTheme}>
        <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
          <StyledCard>
            <div className="brand-name text-align-center">
              <ProgramSchoolLogoBlock
                programLogo={programData.logo}
                programName={programData.title}
                schoolLogo={programData.schoolLogo}
                schoolName={programData.schoolName}
              />
            </div>
            <StyledHeadingSmall>Unable to Complete Registration</StyledHeadingSmall>
            <StyledParagraphMedium>
              Sorry, we couldn’t complete your registration due to a technical error caused by
              duplicate information in our system.{" "}
            </StyledParagraphMedium>
            <StyledParagraphMedium>
              We’re working to fix it and will email you once it’s resolved. Thank you for your
              patience!
            </StyledParagraphMedium>
          </StyledCard>
        </ThemeProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default SsoUniqueIdentifierMismatch;
